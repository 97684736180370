<template>
  <div>
    <img
      style="width: 100%; display: block"
      src="https://static.5t1800.com/s3/2023/05/09/6459e0ca799cc.jpg"
      alt=""
    />
    <!-- 合作流程 -->
    <div class="flow hidden-xs-only">
      <div class="header flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e1242ce85.png"
          alt=""
        />
        <div>合作流程</div>
      </div>
      <ul class="flex a_c j_s">
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d2e6d66.png"
            alt=""
          />
          <div class="title">初步沟通</div>
          <div>
            您可通过电话、微信与我们联系，在了解薇妮合作细节的同时，便于我们了解您的合作意向，以便给出更好的建议。
          </div>
        </li>
        <img
          class="right"
          src="https://static.5t1800.com/s3/2023/05/09/6459e249f09c6.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d41a927.png"
            alt=""
          />
          <div class="title">双向考察</div>
          <div>
            欢迎您来公司实地考察，以对薇妮有更直观的了解，您也可以在公司现场体验我们的产品和服务项目。
          </div>
        </li>
        <img
          class="right"
          src="https://static.5t1800.com/s3/2023/05/09/6459e249f09c6.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d53676f.png"
            alt=""
          />
          <div class="title">达成意向</div>
          <div>
            达成初步的合作意向后，先签订意向协议，方便公司进行相关资源的准备。
          </div>
        </li>
      </ul>
      <div class="down">
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e24c3a498.png"
          alt=""
        />
      </div>
      <ul class="flex a_c j_s">
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d63e374.png"
            alt=""
          />
          <div class="title">持续帮扶</div>
          <div>
            经营不是一天两天的事情，学会做时间的朋友，在成功的路上持续努力，才能收获更多，薇妮一种与您相伴。
          </div>
        </li>
        <img
          class="right"
          src="https://static.5t1800.com/s3/2023/05/09/6459e24b21b34.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d7a039b.png"
            alt=""
          />
          <div class="title">正式签约</div>
          <div>
            很高兴与正式签约，您将享有公司体系化的帮扶措施，并可随时与客户经理沟通，不必担心孤身奋战。
          </div>
        </li>
        <img
          class="right"
          src="https://static.5t1800.com/s3/2023/05/09/6459e24b21b34.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d9a2c0d.png"
            alt=""
          />
          <div class="title">培训学习</div>
          <div>
            学习产品、专业知识、服务技能是迈向成功的第一步，正式签约后，我们会为您提供具体的学习计划。
          </div>
        </li>
      </ul>
    </div>
    <!-- 合作流程H5 -->
    <div class="flowPhone hidden-sm-and-up">
      <div class="header flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e1242ce85.png"
          alt=""
        />
        <div>合作流程</div>
      </div>
      <ul class="">
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d2e6d66.png"
            alt=""
          />
          <div class="title">初步沟通</div>
          <div>
            您可通过电话、微信与我们联系，在了解薇妮合作细节的同时，便于我们了解您的合作意向，以便给出更好的建议。
          </div>
        </li>
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e24c3a498.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d41a927.png"
            alt=""
          />
          <div class="title">双向考察</div>
          <div>
            欢迎您来公司实地考察，以对薇妮有更直观的了解，您也可以在公司现场体验我们的产品和服务项目。
          </div>
        </li>
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e24c3a498.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d53676f.png"
            alt=""
          />
          <div class="title">达成意向</div>
          <div>
            达成初步的合作意向后，先签订意向协议，方便公司进行相关资源的准备。
          </div>
        </li>
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e24c3a498.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d9a2c0d.png"
            alt=""
          />
          <div class="title">培训学习</div>
          <div>
            学习产品、专业知识、服务技能是迈向成功的第一步，正式签约后，我们会为您提供具体的学习计划。
          </div>
        </li>
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e24c3a498.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d7a039b.png"
            alt=""
          />
          <div class="title">正式签约</div>
          <div>
            很高兴与正式签约，您将享有公司体系化的帮扶措施，并可随时与客户经理沟通，不必担心孤身奋战。
          </div>
        </li>
        <img
          src="https://static.5t1800.com/s3/2023/05/09/6459e24c3a498.png"
          alt=""
        />
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459e0d63e374.png"
            alt=""
          />
          <div class="title">持续帮扶</div>
          <div>
            经营不是一天两天的事情，学会做时间的朋友，在成功的路上持续努力，才能收获更多，薇妮一种与您相伴。
          </div>
        </li>
      </ul>
    </div>
    <!-- 帮扶体系 -->
    <div class="system hidden-xs-only">
      <div class="title">利他、共创、共赢的薇妮帮扶体系</div>
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459e6dbd94b6.png"
        alt=""
      />
    </div>
    <!-- 帮扶体系 H5 -->
    <div class="systemPhone hidden-sm-and-up">
      <div class="title">利他、共创、共赢的薇妮帮扶体系</div>
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459e6dbd94b6.png"
        alt=""
      />
    </div>

    <!-- 把握时代机遇 -->
    <div class="contactBox hidden-xs-only">
      <div class="title">把握时代机遇 开创美丽事业</div>
      <div class="contactBoxBot flex a_c j_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/645890da5242b.png"
          alt=""
        />
        <div>
          <div class="phone">即刻拨打电话：025-86667555</div>
          <div class="huo">或</div>
          <div class="flex a_c">
            <div class="person">添加客户经理<br />企业微信沟通</div>
            <img
              class="code"
              src="https://static.5t1800.com/s3/2023/05/08/6458913c71c45.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 把握时代机遇H5 -->
    <div class="contactBoxPhone hidden-sm-and-up">
      <div class="title">把握时代机遇 开创美丽事业</div>
      <div class="contactBoxBot flex a_c j_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/645890da5242b.png"
          alt=""
        />
        <div>
          <div class="phone">即刻拨打电话：025-86667555</div>
          <div class="huo">或</div>
          <div>
            <div class="person">添加客户经理<br />企业微信沟通</div>
            <img
              class="code"
              src="https://static.5t1800.com/s3/2023/05/08/6458913c71c45.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 留言咨询 -->
    <div class="consult hidden-xs-only">
      <div class="title">————————留言咨询————————</div>
      <!-- 姓名 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <input type="text" placeholder="请输入您的姓名" v-model="userName" />
      </div>
      <!-- 手机号 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <div v-show="isShowPhoneTip" class="textTip">*手机号输入有误</div>
        <input
          @focus="checkInfo"
          @blur="checkMobile"
          type="text"
          placeholder="请输入您的手机号码"
          v-model="userPhone"
        />
      </div>
      <!-- 城市 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <input
          type="text"
          placeholder="请输入您所在的城市"
          v-model="userCity"
        />
      </div>
      <!-- 合作方式 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <input
          type="text"
          placeholder="请输入您意向合作的方式"
          v-model="userCooperate"
        />
      </div>
      <div class="submit" @click="submitInfo">
        <span v-show="subBtStatus == 0">立即提交</span>
        <span v-show="subBtStatus == 1">提交中。。。</span>
        <span v-show="subBtStatus == 2">提交成功</span>
      </div>
      <div class="notes">
        请准确填写您的信息，我们会在24小时内与您取得联系。
      </div>
    </div>
    <!-- 留言咨询H5 -->
    <div class="consultPhone hidden-sm-and-up">
      <div class="title">————留言咨询————</div>
      <!-- 姓名 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <input type="text" placeholder="请输入您的姓名" v-model="userName" />
      </div>
      <!-- 手机号 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <div v-show="isShowPhoneTip" class="textTip">*手机号输入有误</div>
        <input
          @focus="checkInfo"
          @blur="checkMobile"
          type="text"
          placeholder="请输入您的手机号码"
          v-model="userPhone"
        />
      </div>
      <!-- 城市 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <input
          type="text"
          placeholder="请输入您所在的城市"
          v-model="userCity"
        />
      </div>
      <!-- 合作方式 -->
      <div class="inputBox flex a_c" style="margin-top: 20px">
        <input
          type="text"
          placeholder="请输入您意向合作的方式"
          v-model="userCooperate"
        />
      </div>
      <div class="submit">
        <span v-show="subBtStatus == 0" @click="submitInfo">立即提交</span>
        <span v-show="subBtStatus == 1">提交中。。。</span>
        <span v-show="subBtStatus == 2">提交成功</span>
      </div>
      <div class="notes">
        请准确填写您的信息，我们会在24小时内与您取得联系。
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      userName: "", //用户姓名
      userPhone: "", //用户手机号
      userCity: "", //用户城市
      userCooperate: "", //用户意向合作方式
      subBtStatus: 0, //0-立即提交 1-提交中 2-提交成功
      isShowPhoneTip: false,
    };
  },
  metaInfo: {
    title: "Vinistyle 薇妮", // set a title
    meta: [
      {
        name: "keyWords",
        content: "薇妮vinistyle,薇妮,薇妮美肤,薇尼美肤空间,护肤品,化妆品加盟,薇妮化妆品加盟,化妆品,薇妮化妆品,美白,防晒,防晒霜",
      },
      {
        name: "description",
        content: "vinistyle薇妮是江苏苏美国际旗下化妆品品牌，主营功效性护肤产品和解决方案，包括美容院专业线套组以及居家护肤产品，针对用户常见的斑、痘、敏、皱四大类肌肤问题，都有针对性的产品和解决方案。vinistyle薇妮提供整店输出、产品合作、项目合作等各种合作方式，诚邀美容院、皮肤管理工作、化妆品实体店加盟合作，共创美丽事业！",
      },
    ],
  },
  components: {},
  created() {
    this.$store.commit("saveTypeIndex", 4);
  },
  methods: {
    //输入框获取焦点
    checkInfo() {
      this.isShowPhoneTip = false;
    },
    //手机号验证
    checkMobile() {
      var mobile = this.userPhone;
      if (!/^1[3456789]\d{9}$/.test(mobile)) {
        this.isShowPhoneTip = true;
      }
    },
    //提交信息
    submitInfo() {
      let _self = this;
      console.log(
        _self.userName,
        _self.userPhone,
        _self.userCity,
        _self.userCooperate
      );
      if (
        _self.userName &&
        _self.userPhone &&
        _self.userCity &&
        _self.userCooperate
      ) {
        _self.subBtStatus = 1;
        axios({
          method: "post",
          url: "/api/cgi-bin/webhook/send?key=3afe0347-6c23-4444-a8c4-494cddd227a3",
          data: {
            msgtype: "markdown",
            markdown: {
              content: `收到新的<font color="info">加盟商意向表单</font><font color="warning">1份</font>，请相关同事注意。\n
         >姓名:<font color="comment">${_self.userName}</font>
         >电话:<font color="comment">${_self.userPhone}</font>
         >城市:<font color="comment">${_self.userCity}</font>
         >合作方式:<font color="comment">${_self.userCooperate}</font>`,
            },
          },
        })
          .then((res) => {
            _self.subBtStatus = 2;
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.flow {
  max-width: 1200px;
  margin: 0 auto;
}
.flow .header {
  padding: 20px 0;
}
.flow .header img {
  width: 47px;
  height: 40px;
  margin-right: 20px;
}
.flow .header div {
  font-size: 38px;
  font-weight: 400;
  color: #000000;
  line-height: 73px;
}
.flow ul li {
  width: 300px;
  font-size: 22px;

  color: #000000;
  line-height: 38px;
}
.flow ul li .title {
  font-size: 26px;
  font-weight: bold;
  padding: 8px 0;
}
.flow ul li img {
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.flow .right {
  height: 80px;
}
.flow .down {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 110px;
}
.flow .down img {
  height: 124px;
}

.flowPhone {
  padding: 8px;
}
.flowPhone .header {
  padding: 10px 0;
}
.flowPhone .header img {
  width: 40px;
  height: 33px;
  margin-right: 20px;
}
.flowPhone .header div {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  line-height: 73px;
}
.flowPhone ul > img {
  display: block;
  margin: 8px auto;
}
.flowPhone ul li {
  font-size: 20px;
  padding: 0 12px;
  color: #000000;
  line-height: 32px;
}
.flowPhone ul li .title {
  font-size: 22px;
  font-weight: bold;
  padding: 6px 0;
}
.flowPhone ul li img {
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.flowPhone .right {
  height: 80px;
}
.flowPhone .down {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 110px;
}
.flowPhone .down img {
  height: 124px;
}

.system {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.system .title {
  font-size: 35px;
  font-weight: bold;
  color: #737373;
  line-height: 45px;
  text-align: center;
  padding: 30px 0;
}
.system img {
  width: 100%;
}

.systemPhone {
  padding: 8px;
  padding-bottom: 20px;
}
.systemPhone .title {
  font-size: 22px;
  font-weight: bold;
  color: #737373;
  line-height: 38px;
  text-align: center;
  padding: 30px 0;
}
.systemPhone img {
  width: 100%;
}

.contactBox {
  background: #ededed;
  padding: 40px;
}
.contactBox .title {
  font-size: 66px;
  font-weight: bold;
  color: #ff5a5a;
  line-height: 82px;
  text-align: center;
}
.contactBox > div {
  max-width: 1200px;
  margin: 0 auto;
}
.contactBox .contactBoxBot {
  padding: 40px 0;
  flex-wrap: wrap;
}
.contactBox .contactBoxBot > img {
  width: 452px;
  height: 384px;
  margin-right: 70px;
}
.contactBox .phone {
  font-size: 45px;
  font-weight: bold;
  color: #754e31;
  line-height: 72px;
}
.contactBox .huo {
  font-size: 45px;
  font-weight: bold;
  color: #5e5e5e;
  line-height: 72px;
  text-align: center;
}
.contactBox .person {
  font-size: 58px;
  font-weight: bold;
  color: #754e31;
  line-height: 72px;
}
.contactBox .code {
  width: 179px;
  height: 179px;
  margin-left: 100px;
}

.contactBoxPhone {
  background: #ededed;
  padding: 16px;
}
.contactBoxPhone .title {
  font-size: 48px;
  font-weight: bold;
  color: #ff5a5a;
  line-height: 82px;
  text-align: center;
}
.contactBoxPhone > div {
  max-width: 1200px;
  margin: 0 auto;
}
.contactBoxPhone .contactBoxBot {
  padding: 40px 0;
  flex-wrap: wrap;
}
.contactBoxPhone .contactBoxBot > img {
  width: 100%;
}
.contactBoxPhone .phone {
  font-size: 24px;
  font-weight: bold;
  color: #754e31;
  line-height: 72px;
}
.contactBoxPhone .huo {
  font-size: 36px;
  font-weight: bold;
  color: #5e5e5e;
  line-height: 72px;
  text-align: center;
}
.contactBoxPhone .person {
  font-size: 24px;
  font-weight: bold;
  color: #754e31;
  line-height: 42px;
  text-align: center;
}
.contactBoxPhone .code {
  display: block;
  width: 179px;
  height: 179px;
  margin: 0 auto;
}

.consult {
  max-width: 1200px;
  margin: 0 auto;
}
.consult .title {
  font-size: 39px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  padding: 20px 0;
}

.consult .inputBox {
  width: 510px;
  height: 55px;
  border: 1px solid #cdcdcd;
  margin: 0 auto;
  padding-left: 8px;
  box-sizing: border-box;
  position: relative;
  margin-top: 0;
}
.consult .inputBox .textTip {
  color: red;
  position: absolute;
  font-size: 14px;
  bottom: -20px;
  left: 28px;
}
.consult .inputBox input {
  outline: none;
  border: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.consult .submit {
  width: 248px;
  height: 50px;
  background: #a9d889;
  border-radius: 10px;
  font-size: 25px;
  font-weight: 300;
  color: #fcfcfc;
  line-height: 50px;
  text-align: center;
  margin: 30px auto;
  cursor: pointer;
}
.consult .notes {
  font-size: 25px;
  font-weight: 300;
  color: #3b3b3b;
  line-height: 73px;
  text-align: center;
}

.consultPhone {
  padding: 12px;
}
.consultPhone .title {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  padding: 20px 0;
}

.consultPhone .inputBox {
  width: 80vw;
  height: 40px;
  border: 1px solid #cdcdcd;
  margin: 0 auto;
  padding-left: 8px;
  box-sizing: border-box;
  position: relative;
  margin-top: 0;
}
.consultPhone .inputBox .textTip {
  color: red;
  position: absolute;
  font-size: 12px;
  bottom: -20px;
  left: 28px;
}
.consultPhone .inputBox input {
  outline: none;
  border: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.consultPhone .submit {
  width: 228px;
  height: 40px;
  background: #a9d889;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 300;
  color: #fcfcfc;
  line-height: 40px;
  text-align: center;
  margin: 30px auto;
  cursor: pointer;
}
.consultPhone .notes {
  font-size: 18px;
  font-weight: 300;
  color: #3b3b3b;
  text-align: center;
}
</style>

